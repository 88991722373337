console.log('Carreguei o Main');

recebeAffId();

const nome = $('#nome');
const email = $('#email');
const telefonecelular = $('#telefonecelular');
const btnEnviar = $('#enviar');

const formFields = ["nome", "email", "telefonecelular"];

const aff_id = localStorage.getItem('aff_id');
const gclid = localStorage.getItem('gclid');
const fbclid = localStorage.getItem('fbclid');
const origem = localStorage.getItem('origem');

const nomeError = $('#error-nome');
const emailError = $('#error-email');
const telefonecelularError = $('#error-telefonecelular');

// Máscaras
telefonecelular.mask("(00) 00000-0000");

// BLURS
nome.blur(function() { validaNome(); } );
email.blur(function() { validaEmail(); } );
telefonecelular.blur(function() { validaTelefoneCelular(); } );

// ENVIAR BTN
btnEnviar.click(function(){

	validaNome();
	validaEmail();
	validaTelefoneCelular();

	if (errors.length === 0) {
       // Adiciona loader
       btnEnviar.attr('disabled', 'true');
       $('#icone-btn img').remove();
       btnEnviar.append("<img class='icone-btn pb-1 ml-2 loader' src='img/loader.gif'>");

	   $('#icone-btn').hide();
	   localStorage.setItem("ddd", telefonecelular.val().substr(0, 4));

       $.post('http://45.55.56.206/gestor/lead/?????/', {
			nome: nome.val(),
			email: email.val(),
			telefonecelular: telefonecelular.val(),
			aff_id: aff_id,
			origem: origem,
			url: window.location.href

		}, function (data) {
			console.log(data);
 
			 // Possíveis respostas
			 if (data.status) {
				 localStorage.setItem('sucesso', 'SUCESSO');
				 localStorage.setItem("adv_sub", nome.val());
				 localStorage.setItem("adv_sub2", email.val());
				 localStorage.setItem("adv_sub3", telefonecelular.val());
 
				 window.location.href = "sucesso.html";
 
			 } else {
 
				 $('.loader').remove();
				 $('#icone-btn').show();
				 btnEnviar.removeAttr('disabled');
 
				 data.errors.forEach(e => {
 
					 let field = e.field,
						 code = e.code,
						 errorField = `error-${field}`,
						 message = e.message;
 
					 if (formFields.includes(field)) {
 
						 if (code == "400Y1") adicionaErro($("#" + field), $("#" + errorField), errors, "Campo obrigatório", `${field}_blank`);
						 if (code == "400Y2") adicionaErro($("#" + field), $("#" + errorField), errors, message, `${field}_invalido`);
						 if (code == "400Y3") adicionaErro($("#" + field), $("#" + errorField), errors, message, `${field}_invalido`);
 
					 } else {
						 window.location.href = "erro.html";
 
					 }
				 });
			 }
		 });
   }
});